/* eslint-disable max-len */

export const skTranslations = {
  'header.top.menu.about': 'O Škoda Plus',
  'header.top.menu.buying': 'Kupujem vozidlo',
  'header.top.menu.selling': 'Predávam vozidlo',
  'header.top.menu.branches': 'Naše pobočky',
  'header.top.menu.financing': 'Produkty a služby',

  'header.main.menu.title.skodaplus': 'Škoda Plus',
  'header.main.menu.title.offer': 'Ponuka vozidiel',
  'header.main.menu.title.products': 'Produkty a služby',

  'header.main.menu.item.homepage': 'Úvodná stránka',
  'header.main.menu.item.about': 'O Škoda Plus',
  'header.main.menu.item.contact': 'Kontakt',
  'header.main.menu.item.branches': 'Naše pobočky',
  'header.main.menu.item.career': 'Kariéra',
  'header.main.menu.item.buying': 'Kupujem vozidlo',
  'header.main.menu.item.selling': 'Predávam vozidlo',
  'header.main.menu.item.yearly': 'Ročné vozidlá',
  'header.main.menu.item.tried': 'Vyskúšané vozidlá',
  'header.main.menu.item.used': 'Jazdené vozidlá',
  'header.main.menu.item.alternative': 'Alternatívne pohony',
  'header.main.menu.item.watchdog': 'Dopytový agent',
  'header.main.menu.item.models': 'Prehľad modelov v ponuke',
  'header.main.menu.item.financing': 'Financovanie vozidiel',
  'header.main.menu.item.warranty': 'Predĺžená záruka',
  'header.main.menu.item.insurance': 'Škoda poistenie',
  'header.main.menu.item.faq': 'Najčastejšie otázky',
  'header.main.menu.item.gdpr': 'Spracovanie osobných údajov',
  'header.main.menu.item.cookies': 'Informácie o cookies',
  'header.main.menu.item.copyright': 'Copyright',
  'header.main.menu.item.sitemap': 'Mapa stránok',

  'footer.title.information': 'Informácie',
  'footer.title.privacy': 'Ochrana osobných údajov',
  'footer.title.other': 'Ostatné',
  'footer.title.services': 'Služby',
  'footer.item.sitemap': 'Mapa stránok',
  'footer.item.makes': 'Značky v ponuke',
  'footer.item.cookies': 'Cookies',
  'footer.item.cookies.settings': 'Nastavenie cookies',
  'footer.item.suggestions': 'Systém pre spracovanie podnetov',
  'footer.item.contact': 'Kontakt',
  'footer.item.questions': 'Otázky',
  'footer.item.career': 'Kariéra',
  'footer.item.financing': 'Chcem financovať vozidlo',
  'footer.item.insurance': 'Chcem poistiť vozidlo',
  'footer.item.insurance.link': '/financing',
  'footer.bar.plus': 'Plus',
  'footer.claim.plus': 'Plus',
  'footer.claim.text': '©Škoda Auto a.s. {{YEAR}}',

  'livechat.text': 'Môžeme Vám poradiť?',
  'cookies.text1':
    'Táto stránka používa cookies. Viac informácií o spracovaní vašich osobných údajov na ich základe a o vašich právach nájdete v',
  'cookies.link':
    'Informáciu o spracovaní osobných údajov prostredníctvom cookies a iných webových technológií.',
  'cookies.text2':
    'Môžete povoliť súhlas so spracovaním osobných údajov rovnako na účely uloženia preferencií používateľa naprieč webovými stránkami, štatistiku a analýzu správania používateľa, oslovenie s ponukou produktov a prepojenie so sociálnymi sieťami.',
  'cookies.agree': 'Súhlasím',
  'cookies.disagree': 'Nesúhlasím',

  'compatibility.title':
    'Ospravedlňujeme sa, ale Váš prehliadač nepodporuje zobrazenie týchto webových stránok.',
  'compatibility.text1':
    'Táto správa sa Vám zobrazila, pretože používate verziu internetového prehliadača Microsoft Internet Explorer, ktorý stránka z bezpečnostných dôvodov nepodporuje. Pre zvýšenie bezpečnosti a správne zobrazenie stránok odporúčame použiť iný prehliadač.',
  'compatibility.text2':
    'Podporované prehliadače: Microsoft Edge, Google Chrome, Mozilla Firefox, Safari.',
  'compatibility.text3': 'Ďakujeme za pochopenie, Váš program Škoda Plus.',
  'compatibility.action': 'Aktualizovať prehliadač',

  'basic.filter.tab1': 'Viem, aké vozidlo chcem',
  'basic.filter.tab2': 'Viem, na čo vozidlo chcem',
  'basic.filter.dropdown.model.label': 'Model',
  'basic.filter.dropdown.model.choose': 'Vyberte model',
  'basic.filter.dropdown.model.zero.loading': 'Načítanie modelov',
  'basic.filter.dropdown.model.zero.unavailable': 'Nedostupný',
  'basic.filter.dropdown.make.label': 'Značka',
  'basic.filter.dropdown.fuelType.label': 'Typ paliva',
  'basic.filter.dropdown.fuelType.choose': 'Vyberte palivo',
  'basic.filter.dropdown.bodyType.label': 'Karoséria',
  'basic.filter.dropdown.bodyType.choose': 'Vyberte karosériu',
  'basic.filter.slider.price.label': 'Cena',
  'basic.filter.slider.price.max': 'Neobmedzená',
  'basic.filter.slider.price.unit': '\u20ac',
  'basic.filter.slider.mileage.label': 'Najazdené',
  'basic.filter.slider.mileage.max': 'Neobmedzené',
  'basic.filter.slider.mileage.unit': 'Km',
  'basic.filter.slider.firstRegistration.label': 'Do prevádzky',
  'basic.filter.submit.button.text': 'Zobraziť {{carsCount}} {{carsLabel}}',
  'basic.filter.submit.button.text.default': 'Načítanie vozidiel',
  'basic.filter.button.redirect.label': 'Prejsť na ponuku vozidiel s vybranými filtrami',
  'basic.filter.button.advanced': 'Rozšírené vyhľadávanie',

  'purpose.filter.yes': 'Áno',
  'purpose.filter.no': 'Nie',
  'purpose.filter.submit.button': 'Zobraziť {{carsCount}} {{carsLabel}}',
  'purpose.filter.submit.button.default': 'Ukážte mi vozidlá',
  'purpose.filter.button.redirect': 'Prejsť na Prehľad vozidiel',
  'purpose.filter.slider.price.label': 'Cena',
  'purpose.filter.slider.price.max': 'Neobmedzená',
  'purpose.filter.slider.price.unit': '\u20ac',
  'purpose.filter.dropdown.choose': 'Vyberte',
  'purpose.filter.dropdown.not.important': 'Nezáleží',
  'purpose.filter.from-4-to-5': '4 až 5',
  'purpose.filter.five-and-more': '5 a viac',
  'purpose.filter.six-and-more': '6 a viac',
  'purpose.filter.doesnt-matter': 'Nezáleží',
  'purpose.filter.two': '2',
  'purpose.filter.more-then-five': '6 a viac',
  'purpose.filter.less-then-50k': 'Do 50 000 km',
  'purpose.filter.less-then-120k': 'Do 120 000 km',
  'purpose.filter.no-limit': 'Neobmedzené',
  'purpose.filter.small': 'Nízky',
  'purpose.filter.medium': 'Stredný',
  'purpose.filter.big': 'Vysoký',
  'purpose.filter.family': 'Rodina',
  'purpose.filter.traveling': 'Cestovanie',
  'purpose.filter.work': 'Práca',
  'purpose.filter.city': 'Do mesta',
  'purpose.filter.fun': 'Zábava',
  'purpose.filter.seats-count': 'Počet pasažierov',
  'purpose.filter.navigation-system': 'Navigácia',
  'purpose.filter.all-wheel-drive': 'Pohon všetkých kolies',
  'purpose.filter.isofix': 'ISOFIX',
  'purpose.filter.fuel': 'Typ paliva',
  'purpose.filter.mileage': 'Najazdené',
  'purpose.filter.towing-bracket': 'Ťažné zariadenie',
  'purpose.filter.body-type': 'Karoséria',
  'purpose.filter.transmission': 'Prevodovka',
  'purpose.filter.color': 'Farba',
  'purpose.filter.engine-power': 'Výkon',
  'purpose.filter.equipment': 'Výbava',

  'header.search.label': 'Hľadať',
  'header.search.category.dealers': 'Dealeri',
  'header.search.category.pages': 'Stránky',
  'header.search.not.found': 'Nenájdené...',

  'general.skodaPlus': 'Škoda Plus',
  'general.filter.no-limit': 'Neobmedzené',
  'general.video.not.supported': "Sorry, your browser doesn't support embedded videos.",
  'general.currency': '\u20ac',
  'general.noVAT': 'bez DPH',
  'general.monday': 'Pondelok',
  'general.tuesday': 'Utorok',
  'general.wednesday': 'Streda',
  'general.thursday': 'Štvrtok',
  'general.friday': 'Piatok',
  'general.saturday': 'Sobota',
  'general.sunday': 'Nedeľa',
  'general.usedYearPlusCars': 'Ročné, Jazdené a Vyskúšané vozidlá',
  'general.404': 'Späť na list',

  'car.params.carID': 'ID',
  'car.params.type': 'Typ',
  'car.params.fuel': 'Typ paliva',
  'car.params.make': 'Značka',
  'car.params.model': 'Model',
  'car.params.fuelType': 'Typ paliva',
  'car.params.mileage': 'Najazdené',
  'car.params.towing-bracket': 'Ťažné zariadenie',
  'car.params.body-type': 'Karoséria',
  'car.params.engineCapacity': 'Objem motora',
  'car.params.transmission': 'Prevodovka',
  'car.params.color': 'Farba',
  'car.params.paintType': 'Druh farby',
  'car.params.engine-power': 'Výkon',
  'car.params.equipment': 'Výbava',
  'car.params.fromCountry': 'Pôvod',
  'car.params.firstSeller': 'Prvý majiteľ',
  'car.params.originalPrice': 'Cena nového vozidla',
  'car.params.innerEquipment': 'Vnútorná výbava',
  'car.params.outerEquipment': 'Vonkajšia výbava',
  'car.params.safetyEquipment': 'Bezpečnosť a technika',
  'car.params.dealer': 'Predajca',
  'car.params.firstRegistration': 'Do prevádzky',
  'car.params.region': 'Región',
  'car.params.price': 'Cena',
  'car.params.bodyType': 'Karoséria',
  'car.params.condition': 'Stav vozidla',
  'car.params.yes': 'Áno',
  'car.params.no': 'Nie',
  'car.params.audio': 'Audio',
  'car.params.engine': 'Pohon a technika',
  'car.params.crashed': 'Havarované',
  'car.params.uncrashed': 'Nehavarované',
  'car.params.certificateCebia': 'Certifikát Cebia',
  'car.params.other': 'Ostatné',
  'car.params.bodyType.seatsCount': 'Karoséria/počet miest:',
  'car.params.serviceBook': 'Servisná knižka:',
  'car.params.vin': 'VIN:',
  'car.params.demoCar': 'Predvádzacie vozidlo:',
  'car.params.makeAndModel': 'Značka a model',
  'car.params.warrantyTill': 'Záruka do',
  'car.params.instalment': 'Mesačná splátka',
  'car.params.emission': `Emisie CO<sub>2</sub>`,

  'color.label': 'Farba',
  'color.metallic.label': 'Metalický lak',
  'color.undefined.label': 'Undefined color',

  'bodyTypes.suv': 'SUV',
  'bodyTypes.sedan': 'sedan',
  'bodyTypes.hatchback': 'hatchback',
  'bodyTypes.combi': 'kombi',
  'bodyTypes.kabrio': 'Kabriolet',

  'car.props.mileage.label': 'Najazdené:',
  'car.props.mileage.unit': 'Km',
  'car.props.mileage.firstRegistration': 'Do prevádzky:',

  'button.compare.vehicle': 'Porovnať vozidlo',
  'button.compare.vehicle.remove': 'Odobrať z porovnania',
  'button.compare.vehicle.detail': 'Porovnať {{carsCount}} {{carsLabel}}',
  'button.compare.vehicle.detail.remove': 'Zrušiť',
  'compare.financing.price': 'Cena pri financovaní',
  'compare.financing.price.unit.noVAT': ' bez DPH',
  'compare.financing.daily.line1': 'Orientačná denná',
  'compare.financing.daily.line2': 'splátka vrátane poistenia',
  'compare.financing.monthly.line1': 'Orientačná mesačná',
  'compare.financing.monthly.line2': 'splátka',
  'compare.financing.isPreview': 'Predvádzacie vozidlo – dostupnosť na požiadanie',

  'cert.tooltip.vin': 'VIN',
  'cert.tooltip.showVin': 'Zobrazit VIN',
  'cert.tooltip.cebia.download': 'Stiahnuť certifikát CEBIA',
  'carDetail.cebiaButton': 'Zobraziť preverenie Cebia',
  'cert.tooltip.skoda1.download': 'Stiahnuť certifikát Škoda',
  'cert.tooltip.skoda2.download': 'Stiahnuť certifikát Škoda',
  'cert.tooltip.certificate': 'Certifikát',

  'transmission.automatic': 'automatická',
  'transmission.manual': 'manuálna',
  'paintType.pearl': 'perleť',
  'paintType.metallic': 'metalická',
  'paintType.basic': 'základná',

  'car.detail.back': 'Spät na Prehľad vozidiel',
  'car.detail.certificate': 'Certifikát',
  'car.detail.condition': 'Stav vozidla',
  'car.detail.summary': 'Súhrn',
  'car.detail.documents': 'Dokumenty',
  'car.detail.conditionFixed':
    'Automobil bol havarovaný a prešiel kompletnou výmenou všetkých poškodených dielov. Potom prešiel rukami našich servisných technikov a auto je ako nové. Toto garantujeme a na automobil sa vzťahuje klasická záruka ako na auto nebúrané.',
  'car.detail.disclaimer':
    'Údaje nachádzajúce sa v tejto karte vozidla majú informatívny charakter. Táto informatávna ponuka sa môže meniť po dohode s predávajúcim. Z tejto informatívnej ponuky nevzniká nárok na uzavretie zmluvy.',
  'car.detail.serviceInspection': 'STK do',
  'car.detail.technicalInspection': 'STK do',
  'car.detail.unspecified': 'Nešpecifikované',
  'car.detail.certificateCebia': 'Certifikát Cebia',
  'car.detail.carID': 'ID vozidla',
  'car.detail.report': 'Nahlásiť inzerát',
  'car.detail.askUs': 'Máte otázku',
  'car.detail.button': '{{carsCount}} {{carsLabel}} v ponuke',
  'car.detail.tab.equipment': 'Výbava',
  'car.detail.tab.technicalParams': 'Technické parametre',
  'car.detail.tab.note': 'Poznámka',
  'car.detail.tab.warranties': 'Záruka',
  'car.detail.tab.financing': 'Financovanie',
  'car.detail.tab.insurance': 'Poistenie',
  'car.detail.note':
    '„Údaje nachádzajúce sa v ponuke majú len informatívny charakter. Táto informatávna ponuka sa môže meniť po dohode s predávajúcim. Z tejto informatívnej ponuky nevzniká nárok na uzavretie zmluvy.“',
  'car.detail.ask': 'Poptat vozidlo',

  'consent.gdpr.marketing.line1': 'Týmto ',
  'consent.gdpr.marketing.line2':
    ' za účelom Oslovenia s ponukou produktov, služieb a výskumu trhu značky Škoda Auto.',
  'consent.gdpr.marketing.link': 'udeľujem súhlas so spracovaním svojich osobných údajov',
  'consent.marketing.content':
    'Týmto udeľujem súhlas so spracovaním svojich osobných údajov za účelom Oslovenia s ponukou produktov, služieb a výskumu trhu značky Škoda Auto.',
  'consent.gdpr.text.line': 'Týmto ',
  'consent.gdpr.text.link':
    'udeľujem súhlas so spracovaním svojich osobných údajov za účelom Kontaktovania kupujúceho za účelom predaje vybraného Jazdeného vozidla.',

  'dealer.form.carId.label': 'ID vozidla:',
  'dealer.form.imInterestedIn': 'Mám záujem o',
  'dealer.form.carID': 'ID vozidla',
  'dealer.form.name': 'Meno',
  'dealer.form.surname': 'Priezvisko',
  'dealer.form.phone': 'Telefón',
  'dealer.form.mail': 'E-mail',
  'dealer.form.vehicleCondition': 'Stav vozidla',
  'dealer.form.financing': 'Financovania',
  'dealer.form.meetingWithDealer': 'Stretnutie s predajcom',
  'dealer.form.somethingElse': 'Niečo iné',
  'dealer.form.callDealer': 'Zobraziť tel. číslo',
  'dealer.form.sendDataToDealer': 'Odoslať správu predajcovi',
  'dealer.form.message.error': 'Formulár sa nepodarilo odoslať. Skúste znova',
  'dealer.form.message.type': 'Musíte vybrať aspoň jednu možnosť: Mám záujem o',
  'dealer.form.message.sending': 'Odosielam...',
  'dealer.form.message.send': 'Odoslať formulár',
  'dealer.form.message.send.success': 'Formulár bol odoslaný.',
  'dealer.form.message.invalid':
    'Formulár obsahuje chyby. Vyplňte, prosím, správne všetky povinné polia a skúste znova.',
  'dealer.form.call.label': 'Zavolať dealerovi',
  'dealer.form.show.phone.label': 'Zobraziť tel. číslo',

  'extra.features.previewType.request': 'Na otázku',
  'extra.features.previewType.sale': 'Na predaj',
  'extra.features.isSkodaPlus.title': 'Vyskúšané vozidlo',
  'extra.features.isOneYearCar.title': 'Ročné vozidlo',
  'extra.features.isEconomical.title': 'Úsporné vozidlo',
  'extra.features.isPreview.title': 'Predvádzacie vozidlo',
  'extra.features.isPreview.info': 'info o dostupnosti v poznámke',

  'dealer.detail.title': 'O predajcovi',
  'dealer.detail.carCount': '{{carsCount}} {{carsLabel}} v ponuke',
  'dealer.detail.completeOffer': 'Objavte celú ponuku ŠkodaPlus',
  'dealer.detail.goTo': 'Prejsť na',
  'dealer.detail.about': 'O predajcovi',
  'dealer.detail.contact.title': 'Kontaktovať predajcu',
  'dealer.detail.contact.firstName': 'Meno',
  'dealer.detail.contact.lastName': 'Priezvisko',
  'dealer.detail.contact.phone': 'Telefón',
  'dealer.detail.contact.email': 'E-mail',
  'dealer.detail.contact.note': 'Váš komentár*',
  'dealer.detail.contact.error': 'Formulár sa nepodarilo odoslať. Skúste znova',
  'dealer.detail.contact.invalid':
    'Formulár obsahuje chyby. Vyplňte, prosím, správne všetky povinné polia a skúste znova.',
  'dealer.detail.contact.send': 'Odoslať',
  'dealer.detail.contact.sending': 'Odosielam...',
  'dealer.detail.contact.send.success': 'Formulár bol odoslaný.',
  'dealer.detail.contact.consent.line1':
    'Stlačením tlačidla Odoslať potvrdzujem, že som sa oboznámil so ',
  'dealer.detail.contact.consent.line2':
    'Zásadami spracovania mojich osobných údajov za účelom Realizácie predaja vozidla.',

  'recap.bubbles.range': 'až',
  'recap.bubbles.min': 'najmenej',
  'recap.bubbles.max': 'najviac',
  'recap.bubbles.price': 'Cena',
  'recap.bubbles.instalment': 'Splátka',
  'recap.bubbles.price.unit': '\u20ac',
  'recap.bubbles.mileageRange': 'Najazdené',
  'recap.bubbles.mileageRange.unit': 'Km',
  'recap.bubbles.seatsCountRange': 'Počet sedadiel',
  'recap.bubbles.enginePower': 'Výkon motora',
  'recap.bubbles.enginePower.unit': 'kW',
  'recap.bubbles.engineCapacity': 'Obsah motora',
  'recap.bubbles.engineCapacity.unit': 'ccm3',
  'recap.bubbles.doorsCountRange': 'Počet dverí',
  'recap.bubbles.firstRegistration': 'Dátum registrácie',
  'recap.bubbles.removeAll': 'Vymazať všetko',

  'small.filter.title': 'Radenie',
  'small.filter.firstOwner': 'Prvý majiteľ',
  'small.filter.hasServiceBook': 'Servisná knižka',
  'small.filter.isSkodaPlus': 'Vyskúšané vozidlá',
  'small.filter.isOneYearCar': 'Ročné vozidlá',
  'small.filter.isEconomical': 'Úsporné vozidlá',
  'small.filter.isUsed': 'Jazdené vozidlá',
  'small.filter.isDemo': 'Predvádzacie vozidlá',
  'small.filter.PRICE_ASC': 'Cena od najlacnejších',
  'small.filter.PRICE_DESC': 'Cena od najdrahších',
  'small.filter.MILEAGE_ASC': 'Najazdené od najmenšieho',
  'small.filter.MILEAGE_DESC': 'Najazdené od najväčšieho',
  'small.filter.FIRST_REGISTRATION_ASC': 'Rok prihlásenia od najstaršieho',
  'small.filter.FIRST_REGISTRATION_DESC': 'Rok prihlásenia od najmladšieho',
  'small.filter.CREATED_ASC': 'Od najstarších ponúk',
  'small.filter.CREATED_DESC': 'Od najnovších ponúk',

  'toolbar.carDetailName': 'Karta vozidla',
  'toolbar.mySelection': 'Môj výber',
  'toolbar.lastViewed': 'Naposledy prezerané vozidlá',
  'toolbar.showResults': 'Zobraziť',
  'toolbar.loadingResults': 'Načítanie vozidiel',
  'toolbar.loadingResults.threeDots': 'Načítanie vozidiel...',
  'toolbar.mainFilterButton': '{{carsCount}} {{carsLabel}}',
  'toolbar.buttonAdvancedSearch': 'Rozšírené vyhľadávanie',
  'toolbar.compareButton': 'Porovnať {{carsCount}} {{carsLabel}}',

  'filter.close': 'Zavrieť',
  'filter.title': 'Parametre vozidiel',
  'filter.show.line1': '{{carsLabel}}',
  'filter.show.line2': '{{carsLabel}}',
  'filter.show.loading': 'Načítavam vozidlá',
  'filter.button.label': 'Filter',

  'skofinOffers.offer2': 'Zimné kolesá zdarma',

  'selling.images.error': 'Musíte vložiť aspoň 3 fotografie',
  'selling.images.size.error.part1': 'Niektoré súbory nedosahujú min. povolenú veľkosť',
  'selling.images.size.error.part2': 'KB, a preto neboli nahrané',
  'selling.images.fileType.error': 'Niektoré súbory nie sú povoleného typu, a preto neboli nahrané',
  'selling.gdpr.consent.content':
    'Týmto udeľujem súhlas so spracovaním svojich osobných údajov za účelom Sprostredkovania vytvorenia ponuky vozidla k predaji.',
  'selling.marketing.consent.content':
    'Týmto udeľujem súhlas so spracovaním svojich osobných údajov za účelom Oslovenia s ponukou produktov, služieb a výskumu trhu značky Škoda Auto.',

  'selling.gdpr.marketing.line1': 'Týmto ',
  'selling.gdpr.marketing.line2':
    ' za účelom Oslovenia s ponukou produktov, služieb a výskumu trhu značky Škoda Auto.',
  'selling.gdpr.marketing.link': 'udeľujem súhlas so spracovaním svojich osobných údajov',
  'selling.gdpr.text.line': 'Týmto ',
  'selling.gdpr.text.link':
    'udeľujem súhlas so spracovaním svojich osobných údajov za účelom Sprostredkovania vytvorenia ponuky vozidla k predaji.',
  'selling.offer.question': 'Komu chcete vozidlo ponúknuť?',
  'selling.offer.description':
    'Zvoľte prosím mesto odkiaľ ste a odkiaľ nám vozidlo priveziete. Ďalej Vám určením vzdialenosti ponúkneme obchodných partnerov, ktorým sa Ponuka Vášho vozidla k výkupu odošle. Po predchádzajúcej dohode pristavíte Vaše vozidlo na pobočku Škoda Plus.',
  'selling.offer.city': 'Mesto',
  'selling.offer.distance': 'Vzdialenosť',
  'selling.offer.input.distance': 'Zadať vzdialenosť',
  'selling.offer.send': 'Odoslať dopyt',
  'selling.offer.sendForm': 'Odoslať formulár',
  'selling.offer.sending': 'Odosielam...',
  'selling.offer.send.success': 'Formulár bol odoslaný.',
  'selling.offer.error.invalid':
    'Formulár obsahuje chyby. Vyplňte, prosím, správne všetky povinné polia a skúste znova.',
  'selling.offer.error.tryAgain': 'Formulár sa nepodarilo odoslať. Skúste znova',
  'selling.offer.error.dealers':
    'Vo zvolenej oblasti nebol nájdený žiadny obchodný partner Škoda Plus. Rozšírte prosím vzdialenosť hľadania.',
  'selling.offer.closest.partners': 'Najbližší obchodní partneri Škoda Plus:',
  'selling.form.title': 'Osobné údaje',
  'selling.form.firstName': 'Meno',
  'selling.form.lastName': 'Priezvisko',
  'selling.form.phone': 'Telefón',
  'selling.form.contact.preference.label': 'Preferujem kontakt',
  'selling.form.contact.preference.phone': 'Telefonicky',
  'selling.form.contact.preference.email': 'E-mailom',
  'selling.form.email': 'E-mail',
  'selling.form.carDetails.label': 'Údaje o vozidle',
  'selling.form.make': 'Značka*',
  'selling.form.model': 'Model',
  'selling.form.fuel': 'Palivo*',
  'selling.form.mileage': 'Najazdené',
  'selling.form.firstRegistrationYear': 'Rok výroby*',
  'selling.form.vin': 'VIN kód',
  'selling.form.note': 'Stručný popis',
  'selling.form.serviceBook': 'Servisná knižka',
  'selling.form.crashed': 'Nehavarované',
  'selling.form.firstOwner': 'Prvý majiteľ',
  'selling.form.tradeIn': 'Mám záujem o protiúčet',
  'selling.form.loadImages': 'Nahrať fotografie + * (min. veľkosť {{count}} KB)',
  'selling.form.photo.hint': 'Ako majú fotografie vyzerať?',

  'report.wrongPriceText': 'nesprávne uvedená cena',
  'report.carNotAvailableText': 'vozidlo nie je fyzicky prítomné na predajnej ploche u obchodníka',
  'report.wrongPhotoText': 'nesprávne fotografie',
  'report.duplicityText': 'duplicita',
  'report.selledText': 'predané',
  'report.otherText': 'iné nejasnosti v cene',
  'report.state.unsent': 'Nahlásiť problém',
  'report.state.sent': 'Problém nahlásený',
  'report.state.error': 'Chyba, skúsiť znova',
  'report.title': 'Nahlásiť vozidlo',
  'report.text':
    'Ak sa domnievate, že je tento inzerát klamlivý alebo zavádzajúci, môžete na to teraz upozorniť náš tím pre zabezpečenie kvality webu. Inzerát, proti ktorému podávate námietku, preveríme a v prípade preukázateľného porušenia pravidiel pre inzerovanie na našom webe podnikneme potrebné opatrenia. Náš server si vyhradzuje právo na spôsob riešenia námietky. O prijatom opatrení Vás už nebudeme informovať.',
  'report.tooltip.wrongPrice':
    'správne: cena vozidla prezentovaná na ŠkodaPlus je celková cena vozidla s DPH platná pri kúpe za „hotové“ a cena bez DPH. Prípadná iná cena, cena vozidla na leasing, na splátky, financovanie v cene atď. je vždy uvedená v odstavci „ďalšie“',
  'report.tooltip.carNotAvailable':
    'správne: vozidlá vystavené na ŠkodaPlus musia byť fyzicky prítomné na predajnej ploche označenej CI prvkami obchodníka, u ktorého sú prezentované a musia byť označené POS materiálmi',
  'report.tooltip.wrongPhoto':
    'správne: každé vozidlo musí byť nafotené zvlášť a nesmú byť použité ilustračné fotografie',
  'report.tooltip.duplicity': 'jedno vozidlo je zverejnené vo viacerých inzerátoch',
  'report.tooltip.sold':
    'vozidlo je inzerované aj napriek tomu, že je už predané, predajca ho už nemá',
  'report.tooltip.other': 'popíšte nižšie v poznámke',
  'report.note': 'Poznámka k námietke',
  'report.close': 'Zavrieť',

  'technical.parameters.unspecified': 'Nešpecifikované',
  'technical.parameters.evidenceNumber': 'Evidenčné číslo',
  'technical.parameters.color': 'Farba',
  'technical.parameters.colorType': 'Typ farby',
  'technical.parameters.firstOwner': 'Prvý majiteľ',
  'technical.parameters.doorsCount': 'Počet dverí',
  'technical.parameters.seatsCount': 'Počet sedadiel',
  'technical.parameters.consumption': 'Spotreba',
  'technical.parameters.bodyType': 'Karoséria',
  'technical.parameters.origin': 'Pôvod',
  'technical.parameters.serviceInspection': 'STK do',
  'technical.parameters.serviceBook': 'Servisná knižka',

  'list.noResults': 'Vášmu výberu nezodpovedá žiadny záznam, vyhľadajte prosím inú kombináciu...',
  'list.more': 'Načítať viac áut',
  'list.loading': 'Načítanie',

  'watchdog.setup': 'Nastaviť dopytového agenta',
  'watchdog.setup.notification': 'Nastaviť upozornenia',
  'watchdog.setup.carList.button':
    'Nemôžete nájsť svoje vozidlo? Nastavte si strážneho psa a my vám ho nájdeme.',
  'watchdog.count5': '5 oznámení',
  'watchdog.count10': '10 oznámení',
  'watchdog.count20': '20 oznámení',
  'watchdog.week': '7 dní',
  'watchdog.2weeks': '14 dní',
  'watchdog.month': 'mesiac',
  'watchdog.gdpr.line1': 'Týmto ',
  'watchdog.gdpr.line2': ' za účelom Kontaktovania kupujúceho s ponukou jazdených vozidiel.',
  'watchdog.gdpr.link': 'udeľujem súhlas so spracovaním svojich osobných údajov',
  'watchdog.gdpr.consent.content':
    'Týmto udeľujem súhlas so spracovaním svojich osobných údajov za účelom Kontaktovania kupujúceho s ponukou jazdených vozidiel.',
  'watchdog.params.title': 'Parametre vozidla',
  'watchdog.params.description':
    'Zadajte kritéria požadovaného vozidla, a pokiaľ sa vozidlo objaví v našej ponuke, budeme Vás e-mailom ihneď kontaktovať. Ak zadáte mobil, môžete byť telefonicky kontaktovaný predajcom, pokiaľ si to budete priať. Toto má výhodu, že budete mať informácie o dostupnom vozidle ešte pred tým, ako sa objaví v ponuke na webe. Dopyt bude na serveri evidovaný, dokiaľ neuplynie jeho platnosť alebo ak Vám nebol zaslaný príslušný počet oznámení. Môžete uviesť zároveň obe položky.',
  'watchdog.make': 'Značka',
  'watchdog.model': 'Model',
  'watchdog.fuel': 'Palivo',
  'watchdog.bodyType': 'Karoséria',
  'watchdog.region': 'Región',
  'watchdog.price': 'Cena',
  'watchdog.mileage': 'Najazdené',
  'watchdog.firstRegistration': 'Do prevádzky',
  'watchdog.more': 'Viac parametrov',
  'watchdog.advanced': 'Ďalšie parametre',
  'watchdog.firstOwner': 'Prvý majiteľ',
  'watchdog.serviceBook': 'Servisná knižka',
  'watchdog.contact.title': 'Kontaktné údaje a platnosť objednávky',
  'watchdog.contact.email': 'E-mail',
  'watchdog.contact.phone': 'Telefón',
  'watchdog.contact.count': 'Počet oznámení',
  'watchdog.contact.validity': 'Platnosť',
  'watchdog.contact.note': 'Poznámka pre predajcu',
  'watchdog.contact.info':
    'Na e-mail bude systém zasielať upozornenia na vozidlá vyhovujúce zvoleným kritériám. Dopyt bude na serveri evidovaný, dokiaľ neuplynie jeho platnosť alebo dokiaľ Vám nebol zaslaný príslušný počet oznámení. Môžete uviesť zároveň obe položky.',
  'watchdog.contact.error.one': 'Musíte vyplniť aspoň jeden parameter vozidla a kontaktný e-mail',
  'watchdog.contact.error.validity': 'Musíte vyplniť položku Platnosť nebo Počet oznámení.',
  'watchdog.contact.error.note':
    'Vaše správa pre predajcu by nemala byť dlhšia ako 2048 znakov. Teraz má {{count}} znakov.',
  'watchdog.contact.send': 'Odoslať dopyt',
  'watchdog.contact.send.success': 'Formulár bol odoslaný',
  'watchdog.contact.send.error': 'Chyba, skúsiť znova',
  'watchdog.contact.close': 'Zavrieť',

  'sitemap.title': 'Mapa stránok',
  'sitemap.about': 'O Škoda Plus',
  'sitemap.branches': 'Naše pobočky',
  'sitemap.insurance': 'Škoda poistenie',
  'sitemap.warranty': 'Predĺžená záruka',
  'sitemap.faq': 'Najčastejšie otázky',
  'sitemap.contact': 'Kontakt',
  'sitemap.buying': 'Kupujem vozidlo',
  'sitemap.list': 'Vyhľadávanie vozidiel',
  'sitemap.models': 'Prehľad modelov v ponuke',
  'sitemap.alternative': 'Alternatívne pohony',
  'sitemap.watchdog': 'Dopytový agent',
  'sitemap.selling': 'Predávam vozidlo',
  'sitemap.financing': 'Financovanie vozidiel',
  'sitemap.offers': 'Akčné ponuky',
  'sitemap.financing.insurance': 'Doplnkové poistenie',
  'sitemap.vw': 'o Volkswagen Financial Services',

  'dealerMapBranchesList.carsInList': 'vozidiel v ponuke',
  'dealerMap.enterDealerName': 'Zadajte názov predajcu alebo lokalitu',
  'dealerMap.enterDealerName.loading': 'Zadajte názov predajcu alebo lokalitu...',
  'dealerMap.places': 'Miesta',
  'dealerMap.dealersHeader': 'Dealeri',
  'dealerMap.searchButton': 'Vyhľadať',

  '404.title': 'Hups, táto stránka neexistuje!',
  '404.page': 'Stránka nenájdená',

  'brands.loading': 'Načítanie značiek a modelov...',
  'brands.empty': 'Zoznam značiek a modelov je prázdny.',
  'brands.loading.part': 'Načítanie',

  'comparison.carDetail': 'Karta vozidla',
  'comparison.title': 'Porovnanie vozidiel',
  'comparison.title.desktop': 'Porovnanie vozidiel',
  'comparison.export': 'Export do PDF',
  'comparison.share': 'Zdieľať',
  'comparison.noVehicles': `Žiadne vozidlá neboli pridané do porovnania, pridať ich môžete na <a href='/list'>zoznam vozidiel</a>`,
  'comparison.back': 'Späť na môj zoznam',

  'photoSamples.title': 'Fotografie k predávanému vozidlu',
  'photoSamples.text':
    'Všetky fotografie musia byť vo formátu JPG, minimálne rozlíšenie 1024 × 768, do veľkosti 5 MB. Minimálny počet fotografií sú tri, maximálny počet fotografií je 50. Čím viac dobrých fotografií nám poskytnete, tým väčší bude záujem o Vaše vozidlo. Na ilustratívnych fotografiách nižšie sa môžete inšpirovať tým, ako by mohlo nafotenie Vášho vozidla prebiehať.',
  'photoSamples.step.1': 'Pohľad na celé auto šikmo spredu',
  'photoSamples.step.2': 'Pohľad na celé auto šikmo zozadu',
  'photoSamples.step.3': 'Pohľad z ľavého boku',
  'photoSamples.step.4': 'Pohľad spredu',
  'photoSamples.step.5': 'Detail interiéru',

  'car.comparison.title': 'Porovnanie vozidiel',
  'howToChoose.title': 'Podľa čoho vyberáte vozidlo',
  'homepage.title': 'Úvodná stránka',

  'cebia.form.name': 'Jméno',
  'cebia.form.name.error': 'Pole musí byť vyplnené',
  'cebia.form.surname': 'Priezvisko',
  'cebia.form.surname.error': 'Pole musí byť vyplnené',
  'cebia.form.email': 'E-mail',
  'cebia.form.email.error': 'Musí byť vyplnená validná emailová adresa',
  'cebia.form.phone': 'Telefon',
  'cebia.form.phone.error': 'Musí byť vyplnené validné telefónne číslo',
  'cebia.form.send': 'Získat',
  'cebia.form.succes': 'Ďakujeme, certifikát dorazí na Vašu emailovú adresu.',
  'cebia.form.error': 'Pri odosielaní formulára došlo k chybe.',
  'cebia.form.dialogTitle': 'Zkrácený výpis Cebia certifikát',
  'cebia.form.dialogText': 'K zaslání výpisu od Vás budeme potřebovat kontaktní údaje.',

  'technical.parameters.batteryCapacity': 'Kapacita baterie',
} as const
