import { ComponentType, useEffect, useState } from 'react'

// High Order Component pro vypnutí SSR
export const withNoSSR = <P,>(WrappedComponent: ComponentType<P>) => {
  const NoSSRComponent = (props: P) => {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
      // Tento kód se spustí pouze na klientovi
      setIsClient(true)
    }, [])

    // Renderujte obsah pouze na klientovi
    if (!isClient) {
      return null // nebo zobrazte zástupný obsah
    }

    // @ts-ignore
    return <WrappedComponent {...props} />
  }

  return NoSSRComponent
}

export const process_browser = typeof window !== 'undefined'
