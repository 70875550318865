import {
  getGtmDataLayerUtmCampaign,
  gtmDataLayerPush,
} from '../splus-lib/utils/googleTagManager/utils'
import { process_browser } from '../components/withNoSSR'
import { useComponentDidMount } from '../splus-lib/utils/hooks/hooks'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

type Props = {
  children: any
}

const GoogleTagManager = ({ children }: Props) => {
  if (!process_browser) {
    return children
  }

  const router = useRouter()

  const pageView = () => {
    const staticPaths = [
      '/',
      '/about',
      '/contact',
      '/branches',
      '/used-cars',
      '/year-old-cars',
      '/tried-cars',
      '/buying-vehicle',
      '/selling-vehicle',
      '/alternative-drives',
      '/page-watchdog',
      '/overview-models',
      '/financing',
      '/extended-warranty',
      '/insurance',
      '/faq',
      '/processing-of-personal-data',
      '/cookies',
      '/copyright',
      '/sitemap',
    ]
    if (window.dataLayer) {
      if (staticPaths.includes(router.pathname)) {
        gtmDataLayerPush({
          event: 'virtualPV',
          virtualUrl: window.location.pathname + window.location.search + window.location.hash,
          content_type: 'general',
          ...getGtmDataLayerUtmCampaign(),
        })
        gtmDataLayerPush({
          event: 'track-vp',
          virtualPath: window.location.pathname,
          virtualTitle: document.title,
        })
      }
    }
  }

  useComponentDidMount(() => {
    // `routeChangeComplete` won't run on the initial page load,
    // so we need to call pageView manually on component mount
    pageView()
  })

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView)
    return () => {
      router.events.off('routeChangeComplete', pageView)
    }
  }, [router.events])

  return children
}

export default GoogleTagManager

const runForClientOnlyHOF = (fn: () => void) => () => {
  if (process_browser) {
    fn()
  }
}

export const registerGTMScrollPercentageAnalyzer = runForClientOnlyHOF(() => {
  // reset data if URL is changed
  const percentageDepthBreakpoints = [20, 50, 70, 90] // as const
  let scrollGTMState = {} as Record<string, Record<number, boolean>>

  window.addEventListener('scroll', e => {
    const shouldIgnoreURL = (url: string) => url.includes('/list/')

    if (shouldIgnoreURL(window.location.href)) {
      return
    }

    const pageHeight = document.body.scrollHeight
    const scrollPosition = window.scrollY
    const viewHeight = window.innerHeight

    const scrolledInPercent = (scrollPosition / (pageHeight - viewHeight)) * 100

    const pageKey = window.location.href

    // every time when you change the location.href, the state is restored
    if (!scrollGTMState[pageKey]) {
      scrollGTMState = {
        [pageKey]: {
          20: false,
          50: false,
          70: false,
          90: false,
        },
      }
    }

    for (const percent of percentageDepthBreakpoints) {
      const isEventSent = scrollGTMState[pageKey][percent]
      if (!isEventSent && scrolledInPercent >= percent) {
        scrollGTMState[pageKey][percent] = true

        // console.info(pageKey, percent)
        gtmDataLayerPush({
          event: 'trackEvent',
          eventCategory: 'Microsite - skodaplus',
          eventAction: 'Scroll',
          eventLabel: percent,
          'appweb.Name': 'ms_scroll',
        })
        return
      }
    }
  })
})
