import { API_URL } from '../src/splus-lib/utils/languageUtils'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import { useMemo } from 'react'
import fetch from 'isomorphic-unfetch'

// file is copy pasted from this source:
// > https://github.com/vercel/next.js/tree/canary/examples/with-relay-modern
let relayEnvironment: any = null

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise initEnvironment:
const fetchQuery = async (operation: any, variables: any, cacheConfig: any, uploadables: any) => {
  try {
    const res = await fetch(API_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }, // Add authentication and other headers here
      body: JSON.stringify({
        query: operation.text, // GraphQL text from input
        variables,
      }),
    })
    const data = await res.json()
    return data
  } catch (err) {
    console.error('network error')
    console.error(err)
    const fakeInvalidNullableData = { data: {} }
    return Promise.resolve(fakeInvalidNullableData)
  }
}

function createEnvironment(initialRecords: any) {
  return new Environment({
    // Create a network layer from the fetch function
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  })
}

export function initEnvironment(initialRecords?: any) {
  // Create a network layer from the fetch function
  const environment = relayEnvironment ?? createEnvironment(initialRecords)

  // If your page has Next.js data fetching methods that use Relay, the initial records
  // will get hydrated here
  if (initialRecords) {
    environment.getStore().publish(new RecordSource(initialRecords))
  }
  // For SSG and SSR always create a new Relay environment
  if (typeof window === 'undefined') return environment
  // Create the Relay environment once in the client
  if (!relayEnvironment) relayEnvironment = environment

  return relayEnvironment
}

export function useEnvironment(initialRecords?: any) {
  const store = useMemo(() => initEnvironment(initialRecords), [initialRecords])
  return store
}
