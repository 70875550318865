import '../themes/styles/saplus-custom.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { BreakpointProvider } from 'react-socks'
import { ReactRelayContext } from 'react-relay'
import { useComponentDidMount } from '../src/splus-lib/utils/hooks/hooks'
import { useEnvironment } from '../lib/createRelayEnvironment'
import GoogleTagManager, {
  registerGTMScrollPercentageAnalyzer,
} from '../src/common/GoogleTagManager'
import React from 'react'
import Script from 'next/script'

registerGTMScrollPercentageAnalyzer()

const App = ({ Component, pageProps }: { pageProps: any; Component: any }) => {
  const environment = useEnvironment(pageProps.initialRecords)

  useComponentDidMount(() => {
    window.onload = () => {
      document.body.classList.remove('preload')
    }
  })
  return (
    <>
      <Script src='https://cross.skoda-auto.com/sdrive/endpoint.js' strategy='beforeInteractive' />
      <BreakpointProvider>
        <ReactRelayContext.Provider value={{ environment }}>
          <GoogleTagManager>
            <Component {...pageProps} />
          </GoogleTagManager>
        </ReactRelayContext.Provider>
      </BreakpointProvider>
    </>
  )
}

export default App
