import { LOCALE } from '../languageUtils'

// leid is a specific market identifier for skoda cookie manager
export const gtmLeid = LOCALE === 'sk' ? '654' : LOCALE === 'cs' ? '260' : ''

// new (SA cookie manager) GTM dataLayer.push wrapper
// all dataLayer.push should be done via this method
export const gtmDataLayerPush = (gtmData: any) => {
  window.dataLayer?.push(gtmData)
}

// !!! we need to keep old GTM push events
export const gtmOldFormDataLayerPush = (gtmData: any) => {
  window.dataLayer?.push(gtmData)
}

export const getGtmDataLayerUtmCampaign = () => {
  const utmParamsList = [
    'utm_id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ] as const

  type UtmType = { [K in (typeof utmParamsList)[number]]?: string }
  const utmObject: UtmType = {}
  const url = new URL(window.location.href)

  utmParamsList.forEach(utmParam => {
    const param = url.searchParams.get(utmParam)
    if (param) {
      utmObject[utmParam] = param
    }
  })

  return {
    campaign: utmObject,
  }
}

export const gtmForms = {
  submit: (
    // there is currently 5 forms which are analyzed
    eventLabel: 'sellCar' | 'reportCar' | 'watchdog' | 'dealerContact' | 'interestInCar',
    // data: any,
    eventType: 'error' | 'success' | 'pending',
    options?: Object
  ) => {
    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      ...options,
      eventLabel,
      // TODO: should it be there?
      // formData: data
      ...(eventType === 'error'
        ? {
            eventAction: 'Form error',
            'appweb.Name': 'ms_form_error',
          }
        : eventType === 'success'
          ? {
              eventAction: 'Form success',
              'appweb.Name': 'ms_form_success',
            }
          : eventType === 'pending'
            ? {
                eventAction: 'Form pending',
                'appweb.Name': 'ms_form_pending',
              }
            : {}),
    })
  },

  /*
  // TODO: implement
  focus: (fieldName: string) => {
    dataLayer.push({
      event : "trackEvent",
      eventCategory : "Microsite - skodaplus",
      eventAction : "Form field focus",
      eventLabel : "String", //Název pole
      appweb.Name : "ms_form_field_focus"
    });
  }
  */
}
