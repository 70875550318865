import { Lang, RegionCountry } from '../../types.__generated__'
import { csTranslations, skTranslations } from './translations'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const cs = 'cs' as const
const cz = 'cz' as const
const sk = 'sk' as const

const SITE_URL = publicRuntimeConfig.SITE_URL as string
const APP_LANGUAGE = publicRuntimeConfig.APP_LANGUAGE as typeof cs | typeof sk

if (APP_LANGUAGE !== cs && APP_LANGUAGE !== sk) {
  throw new Error(`invalid runtime configuration for ${APP_LANGUAGE}`)
}

export const DOMAIN = {
  cs: SITE_URL,
  sk: SITE_URL,
}[APP_LANGUAGE]

export const API_DOMAIN = {
  cs: SITE_URL,
  sk: SITE_URL,
}[APP_LANGUAGE]

export const API_URL = API_DOMAIN + '/graphql'

export const CULTURE = (
  {
    cs: 'cs-CZ',
    sk: 'sk-SK',
  } as const
)[APP_LANGUAGE]

export const LANGUAGE = APP_LANGUAGE.toUpperCase() as Lang

export const LOCALE = APP_LANGUAGE

export const COUNTRY_CODE = {
  cs: cz.toUpperCase(),
  sk: sk.toUpperCase(),
}[APP_LANGUAGE] as RegionCountry

type Translations = typeof csTranslations | typeof skTranslations
type Param = {
  key: string
  value: string | number
}

export const getTranslation = (key: keyof Translations, params?: Param[]) => {
  const locale = LOCALE
  if (isValidTranslationKey(key)) {
    let file = undefined
    if (locale === cs) {
      file = csTranslations
    }
    if (locale === sk) {
      file = skTranslations
    }
    if (file) {
      if (params === undefined) {
        return file[key]
      } else {
        let valueToFormat = file[key].toString()
        params.forEach(param => {
          valueToFormat = valueToFormat.replace(`{{${param.key}}}`, param.value.toString())
        })
        return valueToFormat
      }
    }
  }
  return key
}

const isValidTranslationKey = (key: string): key is keyof Translations => {
  const locale = LOCALE
  if (locale === cs) {
    return key in csTranslations
  }
  if (locale === sk) {
    return key in skTranslations
  }
  return false
}

export const oneToFive = (number: number) => {
  let arrayOfTranslations: string[] = []
  if (LOCALE === cs) {
    arrayOfTranslations = ['vůz', 'vozy', 'vozů']
  }
  if (LOCALE === sk) {
    arrayOfTranslations = ['vozidlo', 'vozidlá', 'vozidiel']
  }
  if (number === 1) {
    return arrayOfTranslations[0]
  } else if (number >= 5) {
    return arrayOfTranslations[2]
  } else if (number === 0) {
    return arrayOfTranslations[2]
  }
  return arrayOfTranslations[1]
}

export const oneToFiveShow = (number: number) => {
  let arrayOfTranslations: string[] = []
  if (LOCALE === cs) {
    arrayOfTranslations = ['Zobrazen', 'Zobrazeny', 'Zobrazeno']
  }
  if (LOCALE === sk) {
    arrayOfTranslations = ['Zobrazené', 'Zobrazené', 'Zobrazených']
  }
  if (number === 1) {
    return arrayOfTranslations[0]
  } else if (number >= 5) {
    return arrayOfTranslations[2]
  } else if (number === 0) {
    return arrayOfTranslations[2]
  }
  return arrayOfTranslations[1]
}
